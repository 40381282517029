













































































































































































































































































































































































































.shop-num {
  height: 89%;

  ::-webkit-scrollbar {
    display: none;
  }

  .script-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }

  .script-container {
    margin-top: 29px;
    margin-right: 30px;
    height: 89%;
    background: #ffffff;
    border-bottom: 3px solid #f7f8fc;
    border-right: 3px solid #f7f8fc;
    border-left: 3px solid #f7f8fc;
    border-radius: 2px;

    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
    }

    .script-body {
      display: flex;
      overflow-y: auto;

      .script-body-item {
        border: none;
        display: flex;
        align-items: center;
        height: 64px;
        border-bottom: 2px solid #f7f8fc;
      }
    }
  }

  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .no-data-font {
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      color: #a8a8b8;
    }
  }

  .shop-table {
    margin: 15px 35px 0 35px;
    border: 1px solid rgb(233, 232, 232);
    width: auto;
    height: 400px;
    overflow-y: auto;
    border-radius: 5px;

    .shop-table-title {
      padding: 10px 0 0 10px;
      line-height: 20px;
      height: 30px;
      background: #eee;
    }

    .shop-check {
      display: flex;
      align-items: center;
      margin: 20px;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }

  .getBack {
    margin-top: 20px;
    position: absolute;
    bottom: 2%;
    left: 50%;
  }
}
